import React from 'react'
import firebase from 'firebase/app'
import RestClient from './services/restClient'

require('firebase/messaging')


class FirebaseStartComponent extends React.PureComponent {
    static config = {
        apiKey: "AIzaSyAXlz1dpxK_iQYTVBL6RQJJ1ADOWXR3OzQ",
        authDomain: "tpt-app-1bff3.firebaseapp.com",
        projectId: "tpt-app-1bff3",
        storageBucket: "tpt-app-1bff3.appspot.com",
        messagingSenderId: "631381928544",
        appId: "1:631381928544:web:8822fd15f987557b42f105",
        measurementId: "G-VDE8Y7813L"
    }

    constructor(props) {
        super(props)
        this.state = {
            messaging: undefined,
        }
    }

    componentDidMount() {
        try {
            if (!firebase.apps.find(x => x.name == 'TPT')) {
                firebase.initializeApp(FirebaseStartComponent.config, 'TPT')
                const app = firebase.app('TPT');
                this.setState({ messaging: app.messaging() }, () => {
                    this.askPermission()
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    askPermission = async () => {
        try {
            await this.state.messaging.requestPermission()
            const token = await this.state.messaging.getToken().catch(error => null)
            //  guardar token
            // console.log('token ask', token)
            if (token)
                await RestClient.updateFirebaseToken({ token })
            return token
        } catch (error) {
            console.error(error)
        }
    }

    render() {
        return null
    }
}

export default FirebaseStartComponent;
