import React from "react";
import {Route, Switch, Redirect} from "react-router-dom";
// import Loadable from 'react-loadable'
import listRoutes from '../list-routes';

const {routesInsideApp} = listRoutes;

const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      {routesInsideApp.map(route => (
        <Route
          path={route.path}
          component={route.component}
          key={route.path}
          exact={route.exact}
        />
      ))}
    </Switch>
  </div>
);

export default App;
