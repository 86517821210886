import React from "react";
import { Menu, Badge } from "antd";
import { Link } from "react-router-dom";
import { Icon } from "antd";
// import { store } from '../../appRedux/store'
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import { useSelector } from "react-redux";
// import menu from '../../menu';

// async obtenerMenu = () => {
//   await RestClientObj.getPerfilUser().then(response => {
//     return response.menu
//   }).catch(error => {
//     return []
//   })
// };

// console.log('store', store.getState())
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

/* 
  ---- Ejemplo de estructura de MENU ----
  <MenuItemGroup key="main" className="gx-menu-group" title={<IntlMessages id="sidebar.main"/>}>
      <SubMenu key="dashboard" className={getNavStyleSubMenuClass(navStyle)}
              title={<span> <i className="icon icon-dasbhoard"/>
                  <span><IntlMessages id="sidebar.dashboard"/></span></span>}>
          <Menu.Item key="main/dashboard/crypto">
              <Link to="/main/dashboard/crypto">
              <i className="icon icon-crypto"/>
              <span><IntlMessages id="sidebar.dashboard.crypto"/></span>
              </Link>
          </Menu.Item>
      </SubMenu>

      <Menu.Item key="main/widgets">
      <Link to="/main/widgets"><i className="icon icon-widgets"/>
          <span><IntlMessages id="sidebar.widgets"/></span></Link>
      </Menu.Item>
  </MenuItemGroup> 
*/

// const getMenu = async () => {
//   const result = await RestClientObj.getPerfilMenu().then(response => {
//     return response.menu
//   }).catch(error => {
//     return []
//   })

//   return result
// }


// (async () => {
//   const menu = await getMenu()
// })()


const getNavStyleSubMenuClass = (navStyle) => {
  if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
    return "gx-no-header-submenu-popup";
  }
  return "";
};

const getIcon = (item = {}) => {
  const { icon, typeIcon } = item;
  if (typeIcon === 'ant')
    return <Icon className="icon" type={icon} />
  else
    return <i className={icon} />
}

const generateMenuItems = (navStyle, menu) => {

  // const { menuData = [] } = this.props
  // const menuData = this.state.menuData
  const generateItem = item => {

    const { key, title, path, icon, disabled, notification } = item;

    if (path) {
      return (
        <Menu.Item key={key} disabled={disabled}>
          <Link to={path}>
            <span>
              <Badge count={notification ? notification : 0} overflowCount={10} offset={[12, 1]}>
                {icon &&
                  getIcon(item)
                }
                <span>{title}</span>
              </Badge>
            </span>
          </Link>
        </Menu.Item>
      )
    }

    return (
      <Menu.Item key={key} disabled={disabled}>
        <span>
          <Badge count={notification ? notification : 0} overflowCount={10} offset={[12, 1]}>
            {icon &&
              getIcon(item)
            }
            <span>{title}</span>
          </Badge>
        </span>
      </Menu.Item>
    )
  }

  const generateSubmenu = items =>
    items.map(menuItem => {

      const { key, menuGroup, title } = menuItem;

      if (menuGroup) {
        return (
          <MenuItemGroup key={key} className="gx-menu-group" title={title}>
            {menuItem.children && generateSubmenu(menuItem.children)}
          </MenuItemGroup>
        )
      }
      if (menuItem.children) {
        return (
          <SubMenu key={key} className={getNavStyleSubMenuClass(navStyle)}
            title={<span>
              {getIcon(menuItem)}
              <span>{title}</span>
            </span>}
          >
            {menuItem.children && generateSubmenu(menuItem.children)}
          </SubMenu>
        )
      }
      return generateItem(menuItem)
    });


  return menu.map(menuItem => {

    if (menuItem.menuGroup) {
      return (
        <MenuItemGroup key={menuItem.key} className="gx-menu-group" title={menuItem.title}>
          {menuItem.children && generateSubmenu(menuItem.children)}
        </MenuItemGroup>
      )
    }

    if (menuItem.children) {
      return (<SubMenu key={menuItem.key} className={getNavStyleSubMenuClass(navStyle)}
        title={<span>
          {getIcon(menuItem)}
          <span>{menuItem.title}</span>
        </span>}
      >
        {menuItem.children && generateSubmenu(menuItem.children)}
      </SubMenu>)
    }
    return generateItem(menuItem)
  })
}


const SidebarContent = ({ match }) => {

  let { navStyle, themeType, pathname } = useSelector(({ settings }) => settings);
  const { authUser } = useSelector(({ auth }) => auth);

  // console.log('user', authUser)
  const menu = (authUser.menu) ? authUser.menu : []

  const getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };


  const selectedKeys = pathname.substr(1);
  // const defaultOpenKeys = selectedKeys.split('/')[1];


  function pathArray (path) {
      var arr = [];
      path.split('/').map((p,i) => {
        if (i == 0) arr.push(p);
        else {
          arr.push(arr[i - 1] + '/' + p);
        }
      })
      return arr;
  }

  function getKeys(menu) {
    return menu.map(m => {
      if (m.children) {
        return getKeys(m.children);
      }
      else {
        return m.key;
      }
    })
  }

  function flattenDeep(arr1) {
    return arr1.reduce((acc, val) => Array.isArray(val) ? acc.concat(flattenDeep(val)) : acc.concat(val), []);
  }

  let selected = pathArray(selectedKeys); // Arreglo de posibles paths.

  let menuKeys = getKeys(menu);
  menuKeys = flattenDeep(menuKeys); // Array plano de todos los keys del menú

  
  const index = selected.reverse().findIndex(s => menuKeys.indexOf(s) > -1); // Del último al primer posible path se busca la primera coincidencia cen el menú

  if (index > -1) selected = [selected[index]]; // Si se encontró alguna coincidencia, se sobreescribe el array con esa coincidencia, 
                                                // sino se dejan todas las posibilidades como seleccionadas 
  
  return (
    <>

      <SidebarLogo />
      <div className="gx-sidebar-content">
        <div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
          <UserProfile size="large" />
          {/* <AppsNavigation /> */}
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={selected}
            selectedKeys={selected}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline">

            {/* <Menu.Item key="sample">
              <Link to="/dashboard"><i className="icon icon-widgets"/>
                <span>Dashboard</span>
              </Link>
            </Menu.Item> */}

            {generateMenuItems(navStyle, menu)}

          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;