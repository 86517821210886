import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_FACEBOOK_USER_SUCCESS,
  SIGNIN_GITHUB_USER_SUCCESS,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_TWITTER_USER_SUCCESS,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  SW_INIT,
  SW_UPDATE,
  SIGNUP_USER_SUCCESS,
  FILTER_DRIVERS_SEARCH,
  FILTER_ROUTES_SEARCH,
  KEY_ACTIVE_CREDENCIALES,
  FILTER_LOCALIZACION_SEARCH,
  SOCKET_CONNECTED,
  SOCKET_DISCONNECTED,
  USER_UPDATE
} from "constants/ActionTypes";

const INIT_STATE = {
  loader: false,
  alertMessage: '',
  showMessage: false,
  initURL: '',
  socket: null,
  // authUser: localStorage.getItem('user_id'),
  authUser: null
};


export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case KEY_ACTIVE_CREDENCIALES: {
      return {
        ...state,
        KEY_ACTIVE_CREDENCIALES: action.payload
      }
    }
    case FILTER_ROUTES_SEARCH: {
      return {
        ...state,
        FILTER_ROUTES_SEARCH: action.payload
      }
    }
    case FILTER_LOCALIZACION_SEARCH: {
      return {
        ...state,
        FILTER_LOCALIZACION_SEARCH: action.payload
      }
    }
    case FILTER_DRIVERS_SEARCH: {
      return {
        ...state,
        prueba: action.payload
      }
    }
    case SW_INIT: {
      return {
        ...state,
        serviceWorkerInitialized: !state.serviceWorkerInitialized,
      }
    }
    case SW_UPDATE: {
      return {
        ...state,
        serviceWorkerUpdated: !state.serviceWorkerUpdated,
        serviceWorkerRegistration: action.payload,
      }
    }
    case SOCKET_CONNECTED: {
      return {
        ...state,
        socket: action.socket
      }
    }
    case SOCKET_DISCONNECTED: {
      return {
        ...state,
        socket: null
      }
    }
    case SIGNUP_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.authUser
      }
    }
    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.authUser
      }
    }
    case USER_UPDATE: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      }
    }
    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        authUser: null,
        initURL: '/',
        loader: false
      }
    }

    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      }
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false
      }
    }

    case SIGNIN_GOOGLE_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case SIGNIN_FACEBOOK_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case SIGNIN_TWITTER_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case SIGNIN_GITHUB_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      }
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      }
    }
    default:
      return state;
  }
}
