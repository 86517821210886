import React, { useState, useCallback, useContext, useEffect } from "react";
import { Layout, Alert, Icon, notification, Button, message } from "antd";
import { Link, useHistory } from "react-router-dom";
// import { SocketContext } from 'components/Socket/socket'
// import CustomScrollbars from "util/CustomScrollbars";
// import languageData from "./languageData";
import { switchLanguage, toggleCollapsedSideNav } from "../../appRedux/actions/Setting";
// import SearchBox from "components/SearchBox";
// import UserInfo from "components/UserInfo";
import UserProfile from 'containers/Sidebar/UserProfile'
// import AppNotification from "components/AppNotification";
// import MailNotification from "components/MailNotification";
import Auxiliary from "util/Auxiliary";
import '../../containers/style.less'

import { NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE } from "../../constants/ThemeSetting";
import { useDispatch, useSelector } from "react-redux";
import ButtonActualizar from "components/ButtonActualizar";
import FirebaseStartComponent from '../../firebase'
import firebase from 'firebase/app'
require('firebase/messaging')

const { Header } = Layout;

const Topbar = () => {

  const { locale, width, navCollapsed, navStyle } = useSelector(({ settings }) => settings);
  const { searchText, setSearchText } = useState('');
  const authUser = useSelector(({ auth }) => auth.authUser);
  const history = useHistory()

  const dispatch = useDispatch();
  // const socket = useContext(SocketContext)
  const [nuevaActualizacion, setNuevaActualizacion] = useState(false)
  const [mensaje, setMensaje] = useState('')

  // useEffect(() => {
  //   // as soon as the component is mounted, do the following tasks:

  //   // subscribe to socket events
  //   socket.on("nueva_actualizacion", data => {
  //     setMensaje(data)
  //     setNuevaActualizacion(true);
  //   });

  //   socket.emit('join_group', `${authUser.company_type_code}`);


  //   return () => {
  //     // before the component is destroyed
  //     // unbind all event handlers used in this component
  //     socket.off("nueva_actualizacion");
  //   };
  // }, [socket]);

  useEffect(() => {
    setUpFirebaseListener()
  }, [])

  const openNotification = (title, body, callBack) => {
    const key = `open${Date.now()}`
    const btn = (
      <Button
        type="primary"
        size="small"
        onClick={() => {
          notification.close(key)
          callBack()
          // console.log('CLICK NOTIF')
        }}
      >
        Revisar ahora
      </Button>
    )
    notification.config({
      placement: 'topRight',
    })
    notification['info']({
      message: title,
      description: body,
      duration: 0,
      btn,
      key,
    })
  }

  const setUpFirebaseListener = async () => {
    if (!firebase.apps.find(x => x.name == 'TPT')) {
      firebase.initializeApp(FirebaseStartComponent.config, 'TPT')
    }

    // console.log(firebase.apps)

    try {
      const app = firebase.app('TPT');
      const token = await app.messaging().getToken().then(token => token).catch(error => null)
      // console.log({ token })

      if (!token) {
        message.warning('Habilitar Permisos para Notificaciones')
        return;
      }
      const messaging = app.messaging()
      messaging.onMessage((payload) => {
        openNotification(payload.notification.title, payload.notification.body, () => {
          history.push('/monitoreo')
        })
      })

    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Header>
      {navStyle === NAV_STYLE_DRAWER || ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) && width < TAB_SIZE) ?
        <div className="gx-linebar gx-mr-3">
          <i className="gx-icon-btn icon icon-menu"
            onClick={() => {
              dispatch(toggleCollapsedSideNav(!navCollapsed));
            }}
          />
        </div> : null}
      <Link to="/" className="gx-site-logo gx-d-block gx-d-lg-none gx-pointer">
        <img alt="" className="app-top-bar-logo" src={require("assets/images/logo_tpt_bajo.png")} /></Link>
      {nuevaActualizacion && (
        <Alert
          message={<span>
            {`${mensaje}`}
            <ButtonActualizar />
          </span>}
          className='header-notification-blink'
          type="info"
          closable
          onClose={() => { setNuevaActualizacion(false) }}
          icon={<Icon type='message' />}
          showIcon
        />
      )}
      <ul className="gx-header-notifications gx-ml-auto">
        <li className="gx-notify gx-notify-search gx-d-inline-block gx-d-lg-none">
          {/* <p>Nueva Actualizacion</p> */}
          {/* <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={
            <SearchBox styleName="gx-popover-search-bar"
              placeholder="Search in app..."
              onChange={updateSearchChatUser}
              value={searchText} />
          } trigger="click">
            <span className="gx-pointer gx-d-block"><i className="icon icon-search-new" /></span>
          </Popover> */}
        </li>
        {/* {width >= TAB_SIZE ? null :
          <Auxiliary>
            <li className="gx-notify">
              <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={<AppNotification/>}
                       trigger="click">
                <span className="gx-pointer gx-d-block"><i className="icon icon-notification"/></span>
              </Popover>
            </li>

            <li className="gx-msg">
              <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight"
                       content={<MailNotification/>} trigger="click">
                  <span className="gx-pointer gx-status-pos gx-d-block">
                    <i className="icon icon-chat-new"/>
                    <span className="gx-status gx-status-rtl gx-small gx-orange"/>
                  </span>
              </Popover>
            </li>
          </Auxiliary>
        } */}
        {/* <li className="gx-language">
          <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={languageMenu()}
                   trigger="click">
                <span className="gx-pointer gx-flex-row gx-align-items-center">
                  <i className={`flag flag-24 flag-${locale.icon}`}/>
                  <span className="gx-pl-2 gx-language-name">{locale.name}</span>
                  <i className="icon icon-chevron-down gx-pl-2"/>
                </span>
          </Popover>
        </li> */}
        {width >= TAB_SIZE ? null :
          <Auxiliary>
            <li className="gx-user-nav">
              {/* <UserInfo/> */}
              <UserProfile size="small" />
            </li>
          </Auxiliary>
        }
      </ul>
    </Header>
  );
};

export default Topbar;
