import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_FACEBOOK_USER,
  SIGNIN_FACEBOOK_USER_SUCCESS,
  SIGNIN_GITHUB_USER,
  SIGNIN_GITHUB_USER_SUCCESS,
  SIGNIN_GOOGLE_USER,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_TWITTER_USER,
  SIGNIN_TWITTER_USER_SUCCESS,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  SOCKET_CONNECTED,
  SOCKET_DISCONNECTED,
  USER_UPDATE,
  FILTER_DRIVERS_SEARCH,
  FILTER_ROUTES_SEARCH,
  KEY_ACTIVE_CREDENCIALES,
  FILTER_LOCALIZACION_SEARCH
} from "constants/ActionTypes";


export const userUpdate = (user) => {
  return {
    type: USER_UPDATE,
    payload: user
  };
};

export const userSignUp = (user) => {
  return {
    type: SIGNUP_USER,
    payload: user
  };
};

export const connectSocket = () => {
  return {
    type: SOCKET_CONNECTED,
  }
}
export const disconnectSocket = () => {
  return {
    type: SOCKET_DISCONNECTED,
  }
}
export const userSignIn = (parameters, callbackError) => {
  return {
    type: SIGNIN_USER,
    userRequest: parameters,
    authUser: null,
    callbackError: callbackError,
  };
};

export const setFilterRoutes = (payload) => {
  return {
    type: FILTER_ROUTES_SEARCH,
    payload
  }
}

export const setKeyActiveCredenciales = (payload) => {
  return {
    type: KEY_ACTIVE_CREDENCIALES,
    payload
  }
}

export const setFilterLocalizacion = (payload) => {
  return {
    type: FILTER_LOCALIZACION_SEARCH,
    payload
  }
}

export const setFilterDrivers = (payload) => {
  return {
    type: FILTER_DRIVERS_SEARCH,
    payload
  }
};

export const userSignOut = () => {
  return {
    type: SIGNOUT_USER
  };
};
export const userSignUpSuccess = (authUser) => {
  return {
    type: SIGNUP_USER_SUCCESS,
    payload: authUser
  };
};

export const userSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: authUser
  }
};
export const userSignOutSuccess = () => {
  return {
    type: SIGNOUT_USER_SUCCESS,
  }
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};


export const userGoogleSignIn = () => {
  return {
    type: SIGNIN_GOOGLE_USER
  };
};
export const userGoogleSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_GOOGLE_USER_SUCCESS,
    payload: authUser
  };
};
export const userFacebookSignIn = () => {
  return {
    type: SIGNIN_FACEBOOK_USER
  };
};
export const userFacebookSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_FACEBOOK_USER_SUCCESS,
    payload: authUser
  };
};
export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};
export const userTwitterSignIn = () => {
  return {
    type: SIGNIN_TWITTER_USER
  };
};
export const userTwitterSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_TWITTER_USER_SUCCESS,
    payload: authUser
  };
};
export const userGithubSignIn = () => {
  return {
    type: SIGNIN_GITHUB_USER
  };
};
export const userGithubSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_GITHUB_USER_SUCCESS,
    payload: authUser
  };
};
export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};
export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};
