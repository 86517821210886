import React from "react";
import ReactDOM from "react-dom";

import NextApp from './NextApp';
import registerServiceWorker from './registerServiceWorker';
// Add this import:
import { AppContainer } from 'react-hot-loader';
import configureStore from './store';

import {
  SW_INIT,
  SW_UPDATE,
} from "constants/ActionTypes";

const store = configureStore();


// Wrap the rendering in a function:
const render = Component => {
  ReactDOM.render(
    // Wrap App inside AppContainer
    <AppContainer>
      <Component />
    </AppContainer>,
    document.getElementById('root')
  );
};

// Do this once
// registerServiceWorker();

// Render once
render(NextApp);

registerServiceWorker({
  onSuccess: () => store.dispatch({ type: SW_INIT }),
  onUpdate: registration =>
    store.dispatch({ type: SW_UPDATE, payload: registration }),
});
// Webpack Hot Module Replacement API
// if (module.hot) {
//   module.hot.accept('./NextApp', () => {
//     render(NextApp);
//   });
// }
