import React from "react";
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { Route, Switch } from "react-router-dom";
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import rootSaga from "./appRedux/sagas";
// import { SocketContext, socket } from 'components/Socket/socket'
import "assets/vendors/style";
import "styles/wieldy.less";
import rootStore from './appRedux/store';
import FirebaseStartComponent from './firebase'
import restClient from './services/restClient'
import { userSignOut } from './appRedux/actions/Auth'
import "./firebase/firebase";
import App from "./containers/App/index";
import { message } from 'antd'


message.config({
  top: 100,
  duration: 2,
  maxCount: 1
});

const { history, store, sagaMiddleware } = rootStore;


const persistor = persistStore(store, undefined, () => {
  const user = store.getState().auth.authUser;
  restClient.setTokenToAxio(user ? user.token : null)
  restClient.setInterceptor(function (calback_msg) {
    // console.log(calback_msg)
    if (calback_msg) {
      message.warn(calback_msg);
    }
    store.dispatch(userSignOut())
  })
  sagaMiddleware.run(rootSaga)
})

const NextApp = () =>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <FirebaseStartComponent />
      {/* <SocketContext.Provider value={socket}> */}
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/" component={App} />
        </Switch>
      </ConnectedRouter>
      {/* </SocketContext.Provider> */}
    </PersistGate>
  </Provider>;


export default NextApp;
