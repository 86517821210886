import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { connect } from 'react-redux'
import { Avatar, Popover, Row, Col } from "antd";
import { userSignOut } from "appRedux/actions/Auth";
// import { SocketContext } from 'components/Socket/socket'

const UserProfile = (props) => {

  function redirectTo(path) {
    this.props.history.push(path);
  }

  const { authUser, size } = props;
  const dispatch = useDispatch();
  // const socket = useContext(SocketContext)

  const log_out = () => {
    dispatch(userSignOut())
    // socket.close()
  }

  const userMenuOptions = (
    <ul className="gx-user-popover" style={{ textAlign: 'center' }}>
      <Link to={{ pathname: `/transportistas/${authUser.id_company}` }}>
        <li>
          Mi compañía
        </li>
      </Link>
      <Link to={{ pathname: `/configuracion` }}>
        <li>
          Mi cuenta
        </li>
      </Link>
      {/* <a className="gx-link" href={`#/transportistas/${authUser.id_company}`}>Mi compañía</a> */}
      <li onClick={() => log_out()}>Cerrar sesión</li>
    </ul>
  );

  const company_logo = authUser.company_logo ? authUser.company_logo : null;

  return (
    <span>
      {size == 'large' &&
        <span>
          <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
            <Popover placement="bottomRight" content={userMenuOptions} trigger="click">

              {
                company_logo ?
                  <Avatar src={company_logo}
                    style={{ border: '2px solid #c0d0ec', borderRadius: '50%' }}
                    className="gx-size-40 gx-pointer gx-mr-3" alt="" /> :
                  <Avatar style={{ backgroundColor: '#87d068' }} icon="user"
                    className="gx-size-40 gx-pointer gx-mr-3" />
              }

              <span className="gx-avatar-name">
                {/* {authUser.name && authUser.name.length > 0 ? authUser.name : authUser.username} */}
                {`${authUser.name ? authUser.name : ''} ${authUser.last_name ? authUser.last_name : ''}`}
                <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
              </span>
            </Popover>
          </div>
          <div className="gx-flex-row gx-align-items-center gx-mb-2 gx-avatar-row " style={{ justifyContent: 'center' }}>
            <Row>
              <Col md={24} style={{ display: 'flex', justifyContent: 'center' }}>
                <span className="gx-avatar-name">{authUser.work_position}</span>
              </Col>
              <Col md={24} style={{ display: 'flex', justifyContent: 'center' }}>
                <span className="gx-avatar-name" style={{ fontSize: 11 }}>{authUser.role_name}</span>
              </Col>
            </Row>
          </div>
        </span>
      }
      {size == 'small' &&
        <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions}
          trigger="click">
          {
            company_logo ?
              <Avatar src={company_logo}
                className="gx-avatar gx-pointer" alt="" />
              :
              <Avatar style={{ backgroundColor: '#87d068' }} icon="user"
                className="gx-avatar gx-pointer" alt="" />
          }
        </Popover>
      }
    </span>
  )
};

const mapStateToProps = ({ auth }) => {
  // console.log(auth);
  const { authUser } = auth;
  return { authUser }
};

export default connect(mapStateToProps)(UserProfile);
