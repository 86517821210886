import createSagaMiddleware from "redux-saga";
import { applyMiddleware, compose, createStore } from 'redux'
import { persistCombineReducers } from 'redux-persist'
import { routerMiddleware } from 'connected-react-router'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native
import thunk from 'redux-thunk';
import rootSaga from "../sagas/index";
import createRootReducer from '../reducers'
import { createHashHistory } from 'history'
import createEncryptor from 'redux-persist-transform-encrypt'

const createBrowserHistory = require('history').createBrowserHistory;


// export const history = createBrowserHistory();
const history = createHashHistory()
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [thunk, sagaMiddleware, routeMiddleware];

const encryptor = createEncryptor({
  secretKey: '45d18a38ea58417f0e8e3fb524bce2977ca72015',
  onError: function (error) {
    // Handle the error.
  },
})
const persistConfig = {
  transforms: [encryptor],
  key: 'tpt',
  storage,
}

function configureStore() {
  return createStore(createRootReducer, {
    serviceWorkerInitialized: false,
    serviceWorkerUpdated: false,
    serviceWorkerRegistration: null,
  });
}

const store = createStore(
  persistCombineReducers(persistConfig, {
    ...createRootReducer(history),
  }),
  compose(
    applyMiddleware(
      ...middlewares
    ),
  ),
)

// const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const store = createStore(
//   persistCombineReducers(persistConfig, {
//     ...createRootReducer(history),
//   }),
//   composeEnhancer(applyMiddleware(...middlewares))
// )


export default { store, history, sagaMiddleware, configureStore };

// export default function configureStore(preloadedState) {
//   // const store = createStore(
//   //   createRootReducer(history), // root reducer with router state
//   //   preloadedState,
//   //   compose(
//   //     applyMiddleware(
//   //       routerMiddleware(history), // for dispatching history actions
//   //       ...middlewares
//   //     ),
//   //   ),
//   // );

//   sagaMiddleware.run(rootSaga);
//   return store;
// }
