import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import URLSearchParams from 'url-search-params'
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { LocaleProvider } from "antd";
import { IntlProvider } from "react-intl";

import AppLocale from "lngProvider";
import MainApp from "./MainApp";
import { setInitUrl } from "appRedux/actions/Auth";
import { onLayoutTypeChange, onNavStyleChange, setThemeType } from "appRedux/actions/Setting";
import listRoutes from '../../list-routes'
import UtilsObj from 'util/utils';

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL
} from "../../constants/ThemeSetting";

const { routesOutsideApp } = listRoutes;

const accessIsAllowed = (authUser, location) => {

  const { permissions } = authUser;
  const { pathname } = location;

  // console.log(authUser, location)

  let veritication = permissions ? permissions.find(perm => {
    let { url } = perm;
    url = '/' + url;

    const url_array = url.split('/');
    const pathname_array = pathname.split('/');

    let sub_verificacion = true;

    // console.log(pathname_array, url_array)

    if (pathname_array.length != url_array.length) {
      sub_verificacion = false;
    }
    else {
      for (var i = 0; i < url_array.length; i++) {
        const url_param = url_array[i];
        const pathname_param = pathname_array[i];

        // Parámetro numérico
        if (url_param == '{number}') {
          if (isNaN(Number(pathname_param)))
            sub_verificacion = false;
        }
        else {
          if (pathname_param != url_param)
            sub_verificacion = false;
        }
      }
    }

    return sub_verificacion;
  }) : { allowed: false };
  // console.log(veritication)

  // return veritication;
  return veritication == undefined ? false : (veritication.allowed == null) ? false : veritication.allowed
  // ? veritication.allowed : false;
}

const RestrictedRoute = ({ component: Component, location, authUser, ...rest }) =>
  <Route
    {...rest}
    render={props =>
      authUser
        ? (accessIsAllowed(authUser, location) ?
          <Component {...props} />
          :
          <Redirect
            to={{
              pathname: '/error-405',
              state: { from: location }
            }}
          />
        )
        : <Redirect
          to={{
            pathname: '/login',
            state: { from: location }
          }}
        />
    }
  />;

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key))
      return false;
  }
  return true;
}

const App = (props) => {

  const dispatch = useDispatch();
  const { locale, navStyle, layoutType } = useSelector(({ settings }) => settings);
  const { authUser, initURL } = useSelector(({ auth }) => auth);

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();


  useEffect(() => {
    let link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href = "/css/style.css";
    link.className = 'gx-style';
    document.body.appendChild(link);
  });

  useEffect(() => {
    if (initURL === '') {
      dispatch(setInitUrl(location.pathname));
    }
    const params = new URLSearchParams(location.search);

    if (params.has("theme")) {
      dispatch(setThemeType(params.get('theme')));
    }
    if (params.has("nav-style")) {
      dispatch(onNavStyleChange(params.get('nav-style')));
    }
    if (params.has("layout-type")) {
      dispatch(onLayoutTypeChange(params.get('layout-type')));
    }
    setLayoutType(layoutType);
    setNavStyle(navStyle);
  });


  const setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('full-layout');
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove('full-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('boxed-layout');
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('full-layout');
      document.body.classList.add('framed-layout');
    }
  };

  const setNavStyle = (navStyle) => {
    if (navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER) {
      document.body.classList.add('full-scroll');
      document.body.classList.add('horizontal-layout');
    } else {
      document.body.classList.remove('full-scroll');
      document.body.classList.remove('horizontal-layout');
    }
  };

  useEffect(() => {
    // console.log(initURL, location)
    if (location.pathname === '/') {
      if (UtilsObj.isEmpty(authUser) || !authUser) {
        history.push('/login');
      } else if (initURL === '' || initURL === '/' || initURL === '/login') {
        history.push('/dashboard');
      } else {
        history.push(initURL);
      }
    }

    if (location.pathname.includes('/monitoreo_externo')) {
      history.push(`${location.pathname}`)
    }

  }, [authUser, initURL, location, history]);

  const currentAppLocale = AppLocale[locale.locale];

  return (
    <LocaleProvider locale={currentAppLocale.antd}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}>

        <Switch>
          {routesOutsideApp.map((route, index) => {
            return <Route key={`route-${index}`} exact path={`${route.path}`} component={route.component} />
          })}
          <RestrictedRoute path={`${match.url}`}
            authUser={authUser}
            location={location}
            component={MainApp} />
        </Switch>
      </IntlProvider>
    </LocaleProvider>
  )
};

export default memo(App);
