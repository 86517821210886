import React from 'react'
import { Button } from 'antd'

const actualizarPagina = () => {
    window.location.reload(true)
}

function ButtonActualizar() {
    return (
        <Button icon='reload' className='btn-refrescar-pagina' size='small' onClick={() => actualizarPagina()}>Click Aquí</Button>
    )
}

export default ButtonActualizar
